// This file is a custom implementation for @reach/listbox `ListBoxList` component.
// Focused List item was not showing in view when using arrow keys. This code fixes it.
// This fix was obtained from https://github.com/reach/reach-ui/issues/646#issuecomment-711429477

import { useRef, useEffect } from 'react';
import * as ListBox from '@reach/listbox';

export const ListboxList = (props: ListBox.ListboxListProps) => {
  const listRef = useRef(null);
  const { selectedOptionRef, isExpanded, highlightedOptionRef } =
    ListBox.useListboxContext();

  // Ensure that the selected item is in view when opening the popover
  useEffect(() => {
    if (selectedOptionRef.current && isExpanded && listRef.current) {
      selectedOptionRef.current.scrollIntoView({ block: 'nearest' });
      listRef.current.focus();
    }
  }, [listRef.current, isExpanded]);

  // Ensure that the highlighted item is in view at all times when using keyboard
  useEffect(() => {
    const ensureHighlightedInView = () => {
      if (highlightedOptionRef.current) {
        highlightedOptionRef.current.scrollIntoView({ block: 'nearest' });
      }
    };

    window.addEventListener('keydown', ensureHighlightedInView);
    return () => {
      window.removeEventListener('keydown', ensureHighlightedInView);
    };
  }, [highlightedOptionRef.current]);

  return <ul data-reach-listbox-list {...props} tabIndex={-1} ref={listRef} />;
};
