// https://gist.github.com/fnicollier/4258461
// inserts text at cursor position
export function insertAtCaret(inputElement: any, text: string) {
  const txtarea = inputElement;
  const scrollPos = txtarea.scrollTop;
  let strPos = 0;

  // detect browser (FireFox or IE)
  const browser =
    // eslint-disable-next-line
    txtarea.selectionStart || txtarea.selectionStart == '0'
      ? 'ff'
      : document.selection
      ? 'ie'
      : false;

  // set `strPos` based on browser
  if (browser === 'ie') {
    txtarea.focus();
    const range = document.selection.createRange();
    range.moveStart('character', -txtarea.value.length);
    strPos = range.text.length;
  } else if (browser === 'ff') strPos = txtarea.selectionStart;

  // insert text at cursor position
  const front = txtarea.value.substring(0, strPos);
  const back = txtarea.value.substring(strPos, txtarea.value.length);
  txtarea.value = front + text + back;
  strPos += text.length;
  if (browser === 'ie') {
    txtarea.focus();
    const range = document.selection.createRange();
    range.moveStart('character', -txtarea.value.length);
    range.moveStart('character', strPos);
    range.moveEnd('character', 0);
    range.select();
  } else if (browser === 'ff') {
    txtarea.selectionStart = strPos;
    txtarea.selectionEnd = strPos;
    txtarea.focus();
  }
  txtarea.scrollTop = scrollPos;
}
