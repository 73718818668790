import ReactDOM from 'react-dom';
import styled from 'styled-components';
import tokens from 'barn/tokens';
import AlertIcon from 'barn/icons/Alert';
import { fontSizes } from 'barn/tokens/typography';
import { ToastType } from 'src/store/models/save-toast';
import TickCircle from '../TickCircle';
import Spinner from '../Spinner';

const StyledSave = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.toast.bg};
  border-radius: ${tokens.radii[3]};
  position: fixed;
  right: 0;
  bottom: 100px;
  left: 0;
  margin: 0 auto;
  padding: ${tokens.space.padding[4]} ${tokens.space.padding[11]};
  width: max-content;
  font-size: ${fontSizes[3]};
  font-weight: 600;
  color: ${({ theme }) => theme.toast.color};
  z-index: ${tokens.zIndex[8]};
  transition: 0.2s all ease-in-out;

  &.hidden {
    bottom: -100px;
    opacity: 0;
  }

  p {
    margin-left: ${tokens.space.margin[6]};
  }
`;

type Props = {
  isLoading: boolean;
  hidden: boolean;
  children: any;
  type?: ToastType;
  noLeftMargin?: boolean;
};

const Save = ({
  isLoading = false,
  hidden = true,
  children = 'Your changes have been saved',
  type = 'success',
  noLeftMargin = false,
}: Props) => {
  let container = document.querySelector('#toast-root');
  if (!container) {
    container = document.createElement('div');
    container.id = 'toast-root';
    document.body.appendChild(container);
  }

  const customStyle = noLeftMargin
    ? {
        left: '0',
      }
    : {};

  return ReactDOM.createPortal(
    <StyledSave
      data-test={`${type}-toast`}
      className={`${hidden ? 'hidden' : ''}`}
      style={customStyle}
    >
      {isLoading && <Spinner small />}
      {!isLoading && type === 'success' ? <TickCircle done /> : <AlertIcon />}
      <p>{children}</p>
    </StyledSave>,
    container,
  );
};

export default Save;
