import Plan from './Plan';
import { BaseModal } from './Base';

const BrowseAbandonment = () => {
  const planType = 'enterprise';

  return (
    <BaseModal
      name='browse-abandonment-modal'
      utmMedium='browse_abandonment'
      planType={planType}
      style={{
        background: "url('/static/img/BrowseAbandonementPromo.png') no-repeat",
        backgroundPosition: '335px 40px',
        backgroundSize: '55%',
      }}
    >
      <h2 className='title'>Browse Abandonment</h2>
      <Plan planType={planType} testHandle='ba-plan' />

      <p className='description mb-16'>
        A sequence of notifications to remind customers if they view a product
        without adding it to cart.
      </p>
    </BaseModal>
  );
};

export default BrowseAbandonment;
