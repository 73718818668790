import useAuth from 'src/hooks/use-auth';
import { useGlobalState } from 'src/hooks/use-global-state';
import { Plan as StyledPlan } from './styled';

type Props = {
  planType: string;
  testHandle: string;
};

const Plan = ({ planType, testHandle }: Props) => {
  const { user } = useAuth();
  const {
    state: { plans },
  } = useGlobalState('pricing');
  const isTrialAvailable = user.website.is_eligible_for_trial;
  const recommendedPlan = (plans.recommended || {})[planType] || {};

  if (isTrialAvailable) {
    <StyledPlan data-test={testHandle}>
      {recommendedPlan.trial_days}-day free trial with the {planType} plan
      <br />
      (only ${recommendedPlan.price}/month)
    </StyledPlan>;
  }

  return (
    <StyledPlan data-test={testHandle}>
      with the {planType} plan (only ${recommendedPlan.price}/month)
    </StyledPlan>
  );
};

export default Plan;
