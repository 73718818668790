import styled from 'styled-components';
import { fontSizes } from 'barn/tokens/typography';
import tokens from 'barn/tokens';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  padding-left: ${tokens.space.padding[8]};
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: ${tokens.radii[5]};

  &::before {
    content: '';
    background: ${({ theme }) => theme.promotionPopup.before.bg};
    position: absolute;
    top: -110px;
    left: -95px;
    width: 230px;
    height: 230px;
    border-radius: 100%;
    opacity: 0.1;
  }

  &::after {
    content: '';
    background: ${({ theme }) => theme.promotionPopup.after.bg};
    position: absolute;
    bottom: -80px;
    left: -80px;
    width: 230px;
    height: 230px;
    border-radius: 100%;
    opacity: 0.1;
  }
`;

export const Plan = styled.p`
  margin-bottom: ${tokens.space.margin[5]};
  color: ${({ theme }) => theme.promotionPopup.plan.color};
  font-size: ${fontSizes[0]};
  font-weight: 500;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 193px;

  .title {
    margin-bottom: 4px;
    font-weight: 500;
    font-size: ${fontSizes[6]};
  }

  .description {
    margin-bottom: ${tokens.space.margin[3]};
    font-size: ${fontSizes[1]};
    color: ${({ theme }) => theme.promotionPopup.color};
    width: 17.8em;
  }

  .fw-light {
    margin-bottom: ${tokens.space.margin[3]};
    font-size: ${fontSizes[2]};
    color: ${({ theme }) => theme.promotionPopup.fwLight.color};
  }

  .fw-bold {
    margin-bottom: 2px;
    font-size: ${fontSizes[2]};
    font-weight: 600;
  }

  .mb-4 {
    margin-bottom: 4px;
  }

  .mb-8 {
    margin-bottom: 8px;
  }

  .mb-12 {
    margin-bottom: 12px;
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  button {
    width: 17em;
  }
`;
