import Plan from './Plan';
import { BaseModal } from './Base';

const ShippingModal = () => {
  const planType = 'business';

  return (
    <BaseModal
      planType={planType}
      name='shipping-modal'
      utmMedium='shipping_notification'
      style={{
        background: "url('/static/img/ShippingPromo.png') no-repeat",
        backgroundPosition: '290px 100px',
        backgroundSize: '63%',
      }}
    >
      <h2 className='title'>Shipping Notification</h2>
      <Plan planType={planType} testHandle='shipping-plan' />

      <p className='description mb-16'>
        Automated notifications to let your subscribers know when their order is
        fulfilled.
      </p>
    </BaseModal>
  );
};

export default ShippingModal;
