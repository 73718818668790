import styled from 'styled-components';
import tokens from 'barn/tokens';
import { fontSizes } from 'barn/tokens/typography';

const Tooltip = styled.div`
  display: inline-flex;
  position: relative;

  .tiptext {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: ${tokens.zIndex[2]};
    background-color: ${({ theme }) => theme.tooltip.tiptext.bg};
    border-radius: ${tokens.radii[3]};
    padding: ${tokens.space.padding[2]} ${tokens.space.padding[6]};
    width: max-content;
    font-size: ${fontSizes[1]};
    color: ${({ theme }) => theme.tooltip.tiptext.color};
    text-align: left;
    max-width: 250px;
    transition: 0.5s;
  }

  .tiptext--left {
    bottom: 0;
    right: 100%;
  }
  .tiptext--right {
    bottom: 0;
    left: 100%;
  }
  .tiptext--top {
    bottom: 100%;
    left: -50%;
  }
  .tiptext--bottom {
    top: 110%;
    left: 0%;
  }

  &:hover .tiptext {
    visibility: visible;
    opacity: 1;
  }

  a {
    color: ${({ theme }) => theme.tooltip.link.color};
    border-bottom: 1px solid ${({ theme }) => theme.tooltip.link.borderColor};
  }
`;

export default {
  Tooltip,
};
