import Plan from './Plan';
import { BaseModal } from './Base';

const AbandonedCartModal = () => {
  const planType = 'business';

  return (
    <BaseModal
      name='acr-modal'
      planType={planType}
      utmMedium='abandoned_cart'
      style={{
        background: "url('/static/img/ACRPromo.png') no-repeat",
        backgroundPosition: '323px 38px',
        backgroundSize: '58%',
      }}
    >
      <h2 className='title'>Abandoned Cart Recovery</h2>
      <Plan planType={planType} testHandle='acr-plan' />
      <p className='description mb-16'>
        A series of notifications to remind the subscriber about the items they
        forgot in their cart.
      </p>
    </BaseModal>
  );
};

export default AbandonedCartModal;
