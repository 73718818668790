import { Component } from 'react';
import { Text } from 'barn/components/Text';
import { logErrorToSentry, debugLog } from 'src/lib/debug-utils';
import { ignoredErrors } from './ignoredErrors';

export class ErrorBoundary extends Component {
  state = { hasError: false };

  constructor(props) {
    super(props);

    window.addEventListener('error', event => {
      const hasError = !ignoredErrors.find(err => event.message.includes(err));

      if (hasError) {
        debugLog({
          message: 'window errors',
          data: {
            event,
          },
        });
      }

      this.setState({
        hasError,
      });
    });

    window.addEventListener('unhandledrejection', ({ reason }) => {
      this.setState({
        hasError: !ignoredErrors.find(err => reason?.message.includes(err)),
      });
    });
  }

  static getDerivedStateFromError(event) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: !ignoredErrors.find(err => event.message.includes(err)),
    };
  }

  componentDidCatch(error, errorInfo) {
    logErrorToSentry({ error, extras: errorInfo });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            padding: '3rem',
            display: 'grid',
            placeContent: 'center',
          }}
        >
          <Text size={4} variant='primary'>
            Apologies! An error was encountered. Please try the following to
            troubleshoot:
          </Text>
          <ol>
            <Text tag='li' size={4} variant='primary'>
              Refresh the page
            </Text>
            <Text tag='li' size={4} variant='primary'>
              Login again via your platform
            </Text>
            <Text tag='li' size={4} variant='primary'>
              Contact customer support
            </Text>
          </ol>
        </div>
      );
    }

    return children;
  }
}
