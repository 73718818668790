import { useRef } from 'react';
import styled from 'styled-components';
import {
  ListboxOption,
  ListboxPopover,
  ListboxButton,
  ListboxInput,
} from '@reach/listbox';
import VisuallyHidden from '@reach/visually-hidden';
import '@reach/listbox/styles.css';
import Tick from 'barn/icons/Tick';
import ChevronDown from 'barn/icons/ChevronDown';
import ChevronUp from 'barn/icons/ChevronUp';
import colors from 'barn/tokens/colors';
import { StyledDropdownButton } from 'barn/components/Button/styled';
import { ButtonSize } from 'barn/components/Button';
import { ListboxList } from './ListboxList';

type Props = {
  data: Array<Object>;
  selectedItem: string;
  name: string;
  title: string;
  primaryKey?: string;
  labelKey?: string;
  onChange: (newValue: string) => void;
  disabled?: boolean;
  buttonSize?: ButtonSize;
  fullWidth?: boolean;
};

const StyledDropdownButtonContent = styled(StyledDropdownButton)<{
  fullWidth: boolean;
}>`
  border: none;
  padding-right: 0px;
`;

export const SingleSelect = ({
  name,
  data,
  selectedItem,
  title,
  onChange,
  primaryKey = 'value',
  labelKey = 'label',
  disabled = false,
  buttonSize = 'md',
  fullWidth = false,
}: Props) => {
  const buttonRef = useRef(null);

  return (
    <div>
      <VisuallyHidden id={name}>{title}</VisuallyHidden>

      <ListboxInput
        aria-labelledby={name}
        value={selectedItem}
        onChange={onChange}
        disabled={disabled}
      >
        {({ valueLabel, isExpanded }) => (
          <>
            <ListboxButton
              arrow={isExpanded ? <ChevronUp /> : <ChevronDown />}
              data-test={name}
              ref={buttonRef}
              style={{
                width: fullWidth ? '100%' : 'auto',
              }}
            >
              <StyledDropdownButtonContent
                as='span'
                size={buttonSize}
                fullWidth={fullWidth}
              >
                {valueLabel}
              </StyledDropdownButtonContent>
            </ListboxButton>
            <ListboxPopover
              onKeyDownCapture={(event: React.KeyboardEvent) => {
                // When tab key is pressed dropdown is closed and focused is moved back to select box
                // this does not happen by default because of this issue https://github.com/reach/reach-ui/issues/691
                if (event.code === 'Tab' && !!buttonRef.current) {
                  event.preventDefault();
                  buttonRef.current.focus();
                }
              }}
            >
              <ListboxList>
                {data.map(item => (
                  <ListboxOption
                    key={item[primaryKey]}
                    data-test={`${name}-${(item[labelKey] || '')
                      .split(' ')
                      .join('-')
                      .toLowerCase()}`}
                    value={item[primaryKey]}
                  >
                    {item[labelKey]}
                    {item[primaryKey] === selectedItem && (
                      <Tick color={colors.greens[4]} />
                    )}
                  </ListboxOption>
                ))}
              </ListboxList>
            </ListboxPopover>
          </>
        )}
      </ListboxInput>
    </div>
  );
};
