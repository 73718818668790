import Plan from './Plan';
import { BaseModal } from './Base';

const FlashSaleModal = () => {
  const planType = 'enterprise';

  return (
    <BaseModal
      name='flash-sale-modal'
      utmMedium='flash_sale'
      planType={planType}
      style={{
        background: "url('/static/img/FlashSalePromo.png') no-repeat",
        backgroundPosition: '350px -36px',
        backgroundSize: '50%',
      }}
    >
      <h2 className='title mb-8'>Flash Sale</h2>
      <Plan planType={planType} testHandle='flash-sale-plan' />
      <p className='description mb-12'>
        Notify your subscribers about time-sensitive promotions
      </p>
      <p className='description fw-bold'>Notification Expiry</p>
      <p className='description'>
        Get the ability to send notifications which expire after a specified
        time.
      </p>
      <p className='description fw-bold'>Always be relevant</p>
      <p className='description mb-12'>
        With you deciding when a campaign is seen, you can make sure that your
        campaigns are always relevant.
      </p>
    </BaseModal>
  );
};

export default FlashSaleModal;
