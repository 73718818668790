import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { UTMMedium } from 'src/modules/pricing/models';
import { useGlobalState } from './use-global-state';

export const useShowPricing = () => {
  const {
    actions: { showPricing },
  } = useGlobalState('pricing');
  const router = useRouter();
  const path = router.asPath;

  useEffect(() => {
    if (!path.includes('#pricing')) return;

    const { utm_medium = null } = router.query;
    showPricing({
      utmMedium: utm_medium as UTMMedium,
    });
  }, [path]);
};
