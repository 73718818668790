import React from 'react';
import Button from 'barn/components/Button';
import Modal from 'barn/components/Modal/Modal';
import { Stack } from 'barn/components/Stack';
import useAuth from 'src/hooks/use-auth';
import { useGlobalState } from 'src/hooks/use-global-state';
import Spinner from 'barn/components/Spinner';
import { Text } from 'barn/components/Text';
import { UTMMedium } from 'src/modules/pricing/models';
import { ContentWrapper, Body } from './styled';

interface Props {
  children: React.ReactNode;
  style: AnyObject;
  name: string;
  planType: string;
  utmMedium: UTMMedium;
}

export const BaseModal: React.FC<Props> = ({
  children,
  style = {},
  name,
  planType,
  utmMedium,
}) => {
  const {
    actions: { fetchPricing, showPricing },
    state: { plans },
  } = useGlobalState('pricing');
  const { user } = useAuth();
  const isTrialAvailable = user.website.is_eligible_for_trial;
  const {
    state: { isOpen, isPlanChangeInProgress },
    actions,
  } = useGlobalState('promotionPopups');

  const handleClick = async () => {
    if (!plans.isFetching && plans.recommended[planType]) {
      actions.changePlan({
        billing_plan_sku: plans.recommended[planType].sku,
        utm_source: 'dashboard',
        utm_medium: utmMedium,
        coupon_code: null,
      });
    } else {
      showPricing({
        utmMedium,
      });
    }

    actions.hide();
  };

  return (
    <Modal
      type='promotion'
      isOpen={isOpen}
      onRequestClose={actions.hide}
      onAfterOpen={() => {
        fetchPricing();
      }}
    >
      {plans.isFetching ? (
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Spinner centered />
        </div>
      ) : (
        <ContentWrapper style={style}>
          <Body>
            <Stack gap={7} direction='vertical'>
              <div>{children}</div>

              <Stack direction='vertical' gap={3}>
                <Button
                  variant='info'
                  onClick={handleClick}
                  isLoading={isPlanChangeInProgress}
                  testHandle={`${name}-upgrade-btn`}
                >
                  {isTrialAvailable
                    ? 'Start my 14-day free trial'
                    : 'Upgrade to Unlock'}
                </Button>
                <Text size={0} textWrap='nowrap'>
                  60-day money-back guarantee. No questions asked.
                </Text>
              </Stack>
            </Stack>
          </Body>
        </ContentWrapper>
      )}
    </Modal>
  );
};
