/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Picker, EmojiData } from 'emoji-mart';
import noop from 'lodash.noop';
import Popover from './Popover';
import SmileyIcon from '../icons/Smiley';

const StyledEmojiButton = styled.button`
  border: 0;
  /* outline: 0; */
  background: transparent;
  cursor: pointer;
`;

interface EmojiPickerProps {
  placement?: any;
  onSelect?: (data: EmojiData) => void;
}
const EmojiPicker: React.FunctionComponent<EmojiPickerProps> = ({
  placement = 'rightTop',
  onSelect = noop,
}) => {
  const [isPopoverVisible, setPopoverVisibility] = useState(false);

  const handleKeyDown = (event: any) => {
    if (isPopoverVisible && event.key === 'Escape') {
      setPopoverVisibility(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [isPopoverVisible]);

  const handlePopover = e => {
    const isShown = !isPopoverVisible;
    setPopoverVisibility(isShown);

    // Stop bubble up to Popover which triggers visibility close
    e.stopPropagation();
  };

  return (
    <Popover
      placement={placement}
      overlayClassName='barn-emoji-picker'
      destroyTooltipOnHide
      overlay={
        <Picker
          title='Pick your emoji'
          onSelect={onSelect}
          style={{ border: 0 }}
          autoFocus
        />
      }
      isVisible={isPopoverVisible}
      setVisibility={setPopoverVisibility}
    >
      <StyledEmojiButton onClick={handlePopover} tabIndex={0}>
        <SmileyIcon />
      </StyledEmojiButton>
    </Popover>
  );
};

export default EmojiPicker;
