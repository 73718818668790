import React from 'react';
import Styled from './styled';

interface TooltipProps {
  tip: any;
  placement?: 'left' | 'right' | 'top' | 'bottom';
}
const Tooltip: React.FunctionComponent<TooltipProps> = ({
  children,
  tip = '',
  placement = 'left',
}) => {
  return (
    <Styled.Tooltip>
      {children}
      <p className={`tiptext tiptext--${placement}`}>{tip}</p>
    </Styled.Tooltip>
  );
};

export { Tooltip };
