import React from 'react';
import { useGlobalState } from 'src/hooks/use-global-state';
import { PromotionTypes } from 'src/modules/promotion-popups/models';
import AbandonedCartModal from '../components/AbandonedCart';
import ShippingModal from '../components/Shipping';
import HeroImageModal from '../components/HeroImage';
import FlashSaleModal from '../components/FlashSale';
import SmartDeliveryModal from '../components/SmartDelivery';
import BrowserAbandonment from '../components/BrowseAbandonment';
import SegmentationModal from '../components/SegmentationModal';

const PromotionPopups = () => {
  const {
    state: { popupType },
  } = useGlobalState('promotionPopups');

  switch (popupType) {
    case PromotionTypes.AbandonedCart:
      return <AbandonedCartModal />;
    case PromotionTypes.Shipping:
      return <ShippingModal />;
    case PromotionTypes.HeroImage:
      return <HeroImageModal />;
    case PromotionTypes.FlashSale:
      return <FlashSaleModal />;
    case PromotionTypes.SmartDelivery:
      return <SmartDeliveryModal />;
    case PromotionTypes.BrowserAbandonment:
      return <BrowserAbandonment />;
    case PromotionTypes.Segmentation:
      return <SegmentationModal />;
    default:
      return null;
  }
};

export default PromotionPopups;
