import { v1 as uuidv1 } from 'uuid';

interface AlertIconProps {
  color?: any;
  style?: any;
  size?: number;
}
const Alert: React.FunctionComponent<AlertIconProps> = ({
  color = 'currentColor',
  style,
  size = 20,
}) => {
  const id = uuidv1();

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      style={style}
    >
      <mask
        id={`maskAlert${id}`}
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='2'
        y='2'
        width='17'
        height='16'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.00041 11.9995H11.0004V7.9995H9.00041V11.9995ZM9.00041 15.9995H11.0004V13.9995H9.00041V15.9995ZM17.8954 16.5085L10.8954 2.5085C10.5564 1.8305 9.44441 1.8305 9.10541 2.5085L2.10541 16.5085C1.95041 16.8175 1.96741 17.1855 2.14941 17.4805C2.33141 17.7755 2.65341 17.9555 3.00041 17.9555H17.0004C17.3474 17.9555 17.6684 17.7755 17.8514 17.4805C18.0334 17.1855 18.0504 16.8175 17.8954 16.5085Z'
          fill='#31373D'
        />
      </mask>
      <g mask={`url(#maskAlert${id})`}>
        <rect className='iconColor' width='20' height='20' fill={color} />
      </g>
    </svg>
  );
};

export default Alert;
