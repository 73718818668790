import Plan from './Plan';
import { BaseModal } from './Base';

const SegmentationModal = () => {
  const planType = 'enterprise';

  return (
    <BaseModal
      planType={planType}
      name='segmentation-modal'
      utmMedium='segmentation'
      style={{
        background: "url('/static/img/SegmentationPromo.png') no-repeat",
        backgroundPosition: '312px 70px',
        backgroundSize: '65%',
      }}
    >
      <h2 className='title'>Segmentation</h2>
      <Plan planType={planType} testHandle='segment-plan' />

      <p className='description mb-16'>
        Separate the users into different group of people based on their actions
        and user properties.
      </p>
      <p className='description mb-16'>
        Create complex subscriber audiences based on engagement (such as clicks
        or purchases) or properties (such as location, customer tags).
      </p>
    </BaseModal>
  );
};

export default SegmentationModal;
