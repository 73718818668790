import styled from 'styled-components';
import { fontSizes } from 'barn/tokens/typography';
import tokens from 'barn/tokens';

const StyledTextarea = styled.textarea`
  box-sizing: border-box;
  outline: none;
  border-radius: ${tokens.radii[4]};
  border: 1px solid ${({ theme }) => theme.textarea.borderColor};
  background: ${({ theme }) => theme.textarea.bg};
  padding: ${tokens.space.padding[3]} ${tokens.space.padding[4]};
  height: 36px;
  font-size: ${fontSizes[2]};
  font-family: inherit;
  color: ${({ theme }) => theme.textarea.color};
  max-width: 100ch;

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.textarea.hover.borderColor};
  }

  &:focus {
    /*  
       Using box-shadow instead of outline 
       since outline won't have border-radius in Safari and old Edge (v18)
      */
    box-shadow: inset 0 0 0 1px
      ${({ theme }) => theme.textarea.hover.borderColor};
  }

  &[disabled],
  &:disabled {
    color: ${({ theme }) => theme.textarea.disabled.color};

    &:hover {
      border-color: ${({ theme }) => theme.textarea.disabled.hover.borderColor};
    }
  }

  &.error {
    border-color: ${({ theme }) => theme.textarea.error.borderColor};
  }

  &.error:focus {
    box-shadow: inset 0 0 0 1px
      ${({ theme }) => theme.textarea.error.borderColor};
  }

  &.inactive {
    background: ${({ theme }) => theme.textarea.inactive.bg};
    border-color: ${({ theme }) => theme.textarea.inactive.borderColor};
    opacity: 1;
    color: ${({ theme }) => theme.textarea.inactive.color};
  }
`;

type TextAreaProps = {
  fullWidth?: boolean;
  bottomBar?: boolean;
};

const StyledEmojiTextarea = styled(StyledTextarea)`
  display: block;
  padding-right: 44px;
  width: ${(props: TextAreaProps) =>
    `${props.fullWidth ? '100%' : 'max-content'}`};
  min-height: 116px;
  resize: none;

  ${(props: TextAreaProps) =>
    props.bottomBar
      ? `border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;`
      : ''}
`;

export const StyledTextareaWrapper = styled.div`
  position: relative;
  width: ${(props: { fullWidth: boolean }) =>
    `${props.fullWidth ? '100%' : 'max-content'}`};
`;

export const StyledEmojiContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  height: 100%;
`;

export default StyledEmojiTextarea;
