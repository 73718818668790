import Plan from './Plan';
import { BaseModal } from './Base';

const SmartDeliveryModal = () => {
  const planType = 'enterprise';

  return (
    <BaseModal
      name='smartdelivery-modal'
      utmMedium='smart_delivery'
      planType={planType}
      style={{
        background: "url('/static/img/SmartDeliveryPromo.png') no-repeat",
        backgroundPosition: '365px 0',
        backgroundSize: '60%',
      }}
    >
      <h2 className='title mb-8'>Smart Delivery</h2>
      <Plan planType={planType} testHandle='smart-delivery-plan' />
      <p className='description mb-12'>
        Send notifications when your subscribers are clicking.
      </p>
      <p className='description mb-16'>
        Personalize the delivery time of your campaign for each subscriber by
        sending the notification to them when they are most likely to be active.
      </p>
    </BaseModal>
  );
};

export default SmartDeliveryModal;
