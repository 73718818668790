import React, { useRef } from 'react';
import EmojiPicker from '../../EmojiPicker';
import {
  StyledInputContainer,
  StyledLabel,
  StyledAsterisk,
  StyledError,
} from '../input/styled';
import { insertAtCaret } from '../util';
import StyledTextarea, {
  StyledEmojiContainer,
  StyledTextareaWrapper,
} from './styled';

interface TextareaProps {
  onChange: (value: any) => void;
  value?: string | number;
  placeholder?: string;
  name: string;
  label?: string;
  disabled?: boolean;
  style?: AnyObject;
  fullWidth?: boolean;
  isMandatory?: boolean;
  tabIndex?: any;
  maxLength?: number;
  withEmoji?: boolean;
  testHandle?: string;
  error?: string | undefined;
  bottomBar?: React.ReactNode;
}

const Textarea: React.FunctionComponent<TextareaProps> = ({
  onChange,
  name,
  value = '',
  placeholder = '',
  label = null,
  disabled = false,
  style = {},
  fullWidth = false,
  isMandatory = false,
  tabIndex = null,
  maxLength = null,
  withEmoji = true,
  testHandle = 'text-area',
  error,
  bottomBar,
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleChange: ReactEventHandler = event => {
    onChange(event.target.value);
  };

  const handleEmojiSelect = (emoji: any) => {
    insertAtCaret(inputRef.current, emoji.native);
    const newValue = inputRef.current.value;
    onChange(newValue);
  };

  return (
    <StyledInputContainer>
      {label && (
        <StyledLabel>
          {label}
          {isMandatory && <StyledAsterisk>*</StyledAsterisk>}
        </StyledLabel>
      )}
      <StyledTextareaWrapper fullWidth={fullWidth}>
        <StyledTextarea
          ref={inputRef}
          id={name}
          name={name}
          className={error ? 'error' : ''}
          onChange={handleChange}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          style={style}
          fullWidth={fullWidth}
          bottomBar={!!bottomBar}
          tabIndex={tabIndex}
          maxLength={maxLength}
          data-test={testHandle}
        />
        {withEmoji && (
          <StyledEmojiContainer>
            <EmojiPicker onSelect={handleEmojiSelect} />
          </StyledEmojiContainer>
        )}
      </StyledTextareaWrapper>
      {bottomBar}
      {error && (
        <StyledError data-test={`${testHandle}-error`}>{error}</StyledError>
      )}
    </StyledInputContainer>
  );
};

export default Textarea;
