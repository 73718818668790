export const routeChangeErrorWorkaroundMessage =
  'Next.js abort route change workaround. Ignore this error';

export const ignoredErrors = [
  // https://github.com/facebook/react/issues/11538
  "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
  'Script error.',
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications.',
  'Uncaught DOMException',
  'Uncaught SyntaxError',
  'The object can not be found here',
  'Uncaught EvalError: Possible side-effect in debug-evaluate',
  'Failed to fetch',
  'Invariant: attempted to hard navigate to the same URL',
  routeChangeErrorWorkaroundMessage,
];
