import { v1 as uuidv1 } from 'uuid';
import colors from 'barn/tokens/colors';

const Smiley = ({ color = colors.greys[2], style = {} }) => {
  const id = uuidv1();

  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' style={style}>
      <mask
        id={`maskSmiley${id}`}
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='2'
        y='2'
        width='16'
        height='16'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10 2C5.5888 2 2 5.5888 2 10C2 14.4112 5.5888 18 10 18C14.4112 18 18 14.4112 18 10C18 5.5888 14.4112 2 10 2ZM10 16.4C6.4712 16.4 3.6 13.5288 3.6 10C3.6 6.4712 6.4712 3.6 10 3.6C13.5288 3.6 16.4 6.4712 16.4 10C16.4 13.5288 13.5288 16.4 10 16.4ZM7.6 9.2C8.0416 9.2 8.4 8.8424 8.4 8.4C8.4 7.9576 8.0416 7.6 7.6 7.6C7.1584 7.6 6.8 7.9576 6.8 8.4C6.8 8.8424 7.1584 9.2 7.6 9.2ZM12.4 7.6C11.9584 7.6 11.6 7.9576 11.6 8.4C11.6 8.8424 11.9584 9.2 12.4 9.2C12.8416 9.2 13.2 8.8424 13.2 8.4C13.2 7.9576 12.8416 7.6 12.4 7.6ZM11.8434 11.025C11.8186 11.0482 11.2146 11.6002 10.0002 11.6002C8.8026 11.6002 8.1986 11.0642 8.1578 11.0266C7.845 10.7226 7.3442 10.7242 7.0346 11.0346C6.7218 11.3466 6.7218 11.853 7.0346 12.1658C7.1402 12.2722 8.1226 13.2002 10.0002 13.2002C11.877 13.2002 12.8602 12.2722 12.9658 12.1658C13.2746 11.857 13.2738 11.3626 12.9698 11.0482C12.6666 10.7346 12.1626 10.7258 11.8434 11.025Z'
          fill='white'
        />
      </mask>
      <g mask={`url(#maskSmiley${id})`}>
        <rect className='iconColor' width='20' height='20' fill={color} />
      </g>
    </svg>
  );
};

export default Smiley;
