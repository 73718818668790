import styled from 'styled-components';
import { fontSizes } from 'barn/tokens/typography';
import tokens from 'barn/tokens';
import Modal from './Modal';

const StyledTopBar = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.modal.topbar.borderColor};
  padding: ${tokens.space.padding[3]} 0;

  > * {
    width: 33.33%;
  }

  .title {
    text-align: center;
    font-size: ${fontSizes[4]};
    color: ${({ theme }) => theme.modal.topbar.title.color};
  }
`;

const StyledBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

interface WidgetModalProps {
  isOpen: boolean;
  onRequestClose?: () => void;
  title: string;
  topBarWidget?: any;
  testHandle?: string;
}
const WidgetModal: React.FunctionComponent<WidgetModalProps> = ({
  isOpen,
  onRequestClose,
  title,
  children,
  topBarWidget = null,
  testHandle,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      type='widget'
      testHandle={testHandle}
    >
      <>
        <StyledTopBar>
          <div>{topBarWidget}</div>
          <p className='title'>{title}</p>
          <div />
        </StyledTopBar>
        <StyledBlock>{children}</StyledBlock>
      </>
    </Modal>
  );
};

export default WidgetModal;
