import React from 'react';
import Modal from 'barn/components/Modal/Widget';
import { useGlobalState } from 'src/hooks/use-global-state';
import { Text } from 'barn/components/Text';
import { HStack, VStack } from 'barn/components/Stack';
import Button from 'barn/components/Button';
import { startConversationWithUser } from 'src/lib/utils';
import { radii } from 'barn/tokens/misc';
import colors from 'barn/tokens/colors';
import space from 'barn/tokens/space';

const Pricing = () => {
  const {
    state: { isOpen },
    actions,
  } = useGlobalState('pricing');

  return (
    <>
      <Modal
        onRequestClose={actions.closePricing}
        title='Plans & Pricing'
        isOpen={isOpen}
        testHandle='pricing-modal'
      >
        <>
          {/* <CouponDescription />
            <PlansList />
            <UnlimitedSubscribersMessageBar />
            <CurrentPlan /> */}
          <div style={{ padding: '2rem' }}>
            <VStack align='center' gap={5}>
              <Text tag='p'>
                To purchase more impressions for your account, please contact
                support.
              </Text>

              <HStack justify='center' gap={4}>
                <Price impressions='1000' price='10' />
                <Price impressions='3000' price='30' />
                <Price impressions='5000' price='45' />
                <Price impressions='10000' price='80' />
              </HStack>

              <Button
                appearance='primary'
                onClick={() => {
                  startConversationWithUser(
                    'I would like to add more impressions to my account',
                  );
                }}
              >
                Contact Support
              </Button>
            </VStack>
          </div>
        </>
      </Modal>
      {/* <HubspotMeeting /> */}
    </>
  );
};

const Price = ({ impressions, price }) => {
  return (
    <div
      style={{
        backgroundColor: colors.greys[6],
        padding: space.padding[5],
        borderRadius: radii[3],
      }}
    >
      <VStack gap={2} justify='center' align='center'>
        <Text>{impressions} impressions</Text>
        <Text weight='600' size={4}>
          ${price}
        </Text>
      </VStack>
    </div>
  );
};

export default Pricing;
