import Plan from './Plan';
import { BaseModal } from './Base';

const HeroImageModal = () => {
  const planType = 'business';

  return (
    <BaseModal
      name='heroimage-modal'
      planType={planType}
      utmMedium='hero_image'
      style={{
        background: "url('/static/img/HeroImagePromo.png') no-repeat",
        backgroundPosition: '330px -59px',
        backgroundSize: '56%',
      }}
    >
      <h2 className='title'>Hero Image Support</h2>
      <Plan planType={planType} testHandle='hero-image-plan' />
      <p className='description fw-light'>
        Want to make your notifications impressive? Add a big image!
      </p>
      <p className='description fw-bold'>Increase Engagement. Period.</p>
      <p className='description fw-light'>
        Get more clicks on your notifications by making them stand out.
      </p>
    </BaseModal>
  );
};

export default HeroImageModal;
